/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 25px;
    height: 20px;
    left: 36px;
    top: 50%;
    margin-top: -10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #FFF;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: black;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    width: 100%;
    cursor: pointer;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
/* Individual item */
.bm-item {
    display: flex;
    color: #80561b;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

.user-image{
    width: auto;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    width: 80px;
    height: 80px;
}
.main-heading{
    background-color: #e97200;
    width: 100%;
    color: #fff;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
}

.heading-display{
    height: 50px;
    padding-top: 5px;
    padding-right: 5px;
    position: relative;
}
.title{
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 10px;
}
