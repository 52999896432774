#calendar{
    padding: 20px 20px 20px 20px;
}
.my-calendar-container{
    display: inline-block;
}
.calendar-select{
    display: flex;
}

.new-button{
 background-color: #e97200;
    border-color: #e97200;
    border-radius: 0.25em;
    color: #ffffff !important;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.4em;
    cursor: pointer;
    width: 100%;
    text-align: center !important;
    align-items: center !important;
}


.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    height: 100vh;
}

.box {
    position: relative;
    max-width: 100vw;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #999;
    max-height: calc(100vh - 15vh - 40px);
    overflow-y: scroll;
}

.close-icon {
    content: 'x';
    color: black;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
}
.input-group {
    line-height: 1.5;
    -mox-osx-font-smoothing: grayscale;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.input-group > :first-child,
.input-group > :first-child.input-group-button > * {
    border-radius: 0;
}
.input-group > :last-child,
.input-group > :last-child.input-group-button > * {
    border-radius: 0;
}

.input-group-label {
    padding: 0 1rem;
    border: 1px solid #cacaca;
    background: #e6e6e6;
    color: #0a0a0a;
    text-align: center;
    white-space: nowrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.input-group-label:first-child {
    border-right: 0;
}
.input-group-label:last-child {
    border-left: 0;
}
.input-group-field {
    border-radius: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
    min-width: 0;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: .5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    -webkit-box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
    box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0a0a0a;
}

.input-group-button,
.input-group-button a,
.input-group-button button,
.input-group-button input,
.input-group-button label,
.input-group-field,
.input-group-label {
    margin: 0;
    white-space: nowrap;
}

.input-group-field-textarea {
    border-radius: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
    min-width: 0;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: .5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    -webkit-box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
    box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0a0a0a;
}

.expanded{

    width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    margin-right: 0px;
    margin-left: 0px;
    padding: .85em 1em;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-transition: background-color .25s ease-out,color .25s ease-out;
    transition: background-color .25s ease-out,color .25s ease-out;
    font-family: inherit;
    font-size: .9rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #e97200;
    color: #fefefe;
}
.form-error{
    margin-top: -.5rem;
    margin-bottom: 1rem;
    font-size: .75rem;
    font-weight: 700;
    color: #cc4b37;
    text-align: left;
}
@media screen and (min-width: 640px) {
    body .fc-view {
        font-size: 1.2em;
    }
    .box{
        min-width: 500px;
    }
}
#html-container{
    position: relative;
}
#top-right{
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
}
a.fc-daygrid-dot-event{
    color: black !important;
}
.fc-day-grid-container.fc-scroller {
    height: auto!important;
    overflow-y: auto !important;
}
#calendar{
    height: auto !important;
}
